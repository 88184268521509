<template>
<div id="content" class="site-content"><div id="primary" class="content-area width-full">
    <main id="main" class="site-main">
        <div class="cont">
            <h1 class="maincont-ttl">Check Out</h1>

            <ul class="b-crumbs">
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/cart">Cart</router-link></li>
                <li>Check Out</li>
            </ul>
        </div>
        <div class="maincont page-styling page-full">
            <div class="container mb70 page-styling row-wrap-container row-wrap-nottl">
                <div class="row row-eq-height">
                    <div class="col-xs-12 col-md-6">
                        <AddressForm/>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <Payment/>
                    </div>
                </div>
                <div class="cart-actions row">
                    <div class="col-xs-3" v-if="!sparrowConnected">
                        <a href="#" id="connect-sparrow" class="checkout-button button" v-on:click.stop.prevent="connectSparrow">Connect Sparrow</a>
                    </div>
                    <div v-else class="col-xs-3 text">
                        Sparrow connected.
                    </div>
                    <div class="col-xs-6 text">
                        <span v-if="sparrowConnected && insufficientFunds">Account has insufficient <img class="prod-price-symbol" src="/img/10grans.png"> for purchase.</span>
                        <span v-if="sparrowConnected && !insufficientFunds">Account has {{prettyPrice(balance)}} and total is {{prettyPrice(total)}}.</span>
                    </div>
                    <div class="col-xs-3">
                        <a href="#" class="checkout-button button">Complete Transaction</a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div></div>
</template>
<style scoped>
.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.prod-price-symbol {
    width: 14px;
    height: 14px;
}
</style>
<script>
import AddressForm from '../components/AddressForm.vue'
import Payment from '../components/Payment.vue'
import { ethers } from 'ethers'
import {BigNumber} from 'bignumber.js'
import { bus } from '../main'

const ERC20Abi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function balanceOf(address) view returns (uint)",
  "function transfer(address to, uint amount)",
  "function approveAndCall(address, uint256, bytes) returns (bool)",
  "event Transfer(address indexed from, address indexed to, uint amount)"
];

let provider;
//let signer;
let contract;

export default {
    components: {
        AddressForm,
        Payment
    },
    computed: {
        balanceStr() {
            console.log(`balance: ${this.balance}`);
            return this.balance ? ethers.utils.formatEther(this.balance.toString()) : 'n/a';
        },
        total() {
            return this.$store.getters.getCartTotal;
        },
        insufficientFunds() {
            if (this.balance) {
                return this.balance.lt(this.total);
            }
            else {
                console.log('balance not set yet');
                return true;
            }
        },
        accountAddress: {
            get() {
                return this.$store.getters.getAccountAddress;
            },
            set(address) {
                this.$store.dispatch('updateAccountAddress', address);
            }
        },
        authToken: {
            get() {
                return this.$store.getters.getAuthToken;
            },
            set(token) {
                this.$store.dispatch('updateAuthToken', token);
            }
        }
    },
    created() {
        if (this.sparrowConnected) {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
                this.accountAddress = ethers.utils.getAddress(accounts[0]);
            })
            .then(this.setup());
        }
    },
    data() {
        return {
            message: '',
            balance: new BigNumber(0), // in wei,
            registered: false,
            sparrowConnected: false
        }
    },
    methods: {
        connectSparrow() {
            try {
                this.message = '';
                window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
                    console.log(`Chain ID: ${window.ethereum.chainId}`);
                    this.accountAddress = ethers.utils.getAddress(accounts[0]);
                    this.setup();
                });
            }
            catch(e) {
                console.error(e);
                bus.emit('toast', {
                    position: 'center',
                    duration: 8000,
                    text: 'Sparrow doesn\'t seem to be installed. Click for install instructions.',
                    backgroundColor: '#FF0000',
                    newWindow: true,
                    onClick: () => { window.open('https://blog.ubiqsmart.com/the-ubiq-dex-using-sparrow-96bb604a1c89'); }
                });
            }
        },
        async setup() {
            console.log('setup')
            provider = new ethers.providers.Web3Provider(window.ethereum);

            (this.authToken ? this.authenticate() : this.register()).then(() => {
                console.log('getting balance')
                contract = new ethers.Contract('0x0826180A4c981d5095Cb5c48BB2A098A44cf6f73', ERC20Abi, provider);
                return contract.balanceOf(this.accountAddress)
            })
            .then(bal => {
                console.log(`bal: ${bal}`);
                this.balance = new BigNumber(bal.toString());
                this.sparrowConnected = true;
            });
        },
        prettyPrice(bn) {
            return new BigNumber(bn).dividedBy(new BigNumber('1e+18').toString());
        },
        async register() {
            const now = Date.now();
            const address = this.accountAddress;
            const message = `${now} Using address: ${address} for payment on onlygrans.xyz`;
            return provider.provider.sendAsync({ method: 'personal_sign', params: [message, address], from: address}, (err, res) => {
                console.log('stuff')
                if (err) {
                    console.error("failed to sign message");
                    return
                }
                const signature = res.result;
                console.log(`Registering: ${signature}`);
                return fetch('/api/account/register', { method: 'post', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                    now,
                    address,
                    message,
                    signature
                })})
                .then(response => response.json())
                .then(returnData => {
                    if (returnData.error) {
                        console.error('Error getting registration response', returnData.error);
                    }
                    else {
                        this.registered = true;
                        this.authToken = returnData.token;
                    }
                });
            });
        },
        async authenticate() {
            console.log('authenticate')
            const now = Date.now();
            const address = this.accountAddress;
            const message = `${now} ${address} ${this.authToken} auth`;
            const hash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(message));
            return fetch('/api/account/auth', { method: 'post', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                now,
                address,
                hash
            })})
            .then(response => response.json())
            .then(returnData => {
                if (returnData.error) {
                    console.error('Error getting auth response', returnData.error);
                    return this.register();
                }
                else {
                    this.registered = true;
                    console.log('authenticated');
                }
            });
        }
    }
}
</script>
