import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt'
import router from './router'
import store from './store'

import "toastify-js/src/toastify.css"

export const bus = mitt()
const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
