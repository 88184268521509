import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { BigNumber } from 'bignumber.js'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = new Vuex.Store({
    state: {
        products: [], // simple array of product data & quantity objects
        cart: {} // key is productId, value is quantity
    },
    mutations: {
        initializeProducts(state, products) {
            for (const product of products) {
                product.price = new BigNumber(product.price);
            }
            state.products = products;
        },
        addToCart(state, cartItem) {
            const productId = cartItem.productId;
            const quantity = cartItem.quantity;
            console.log(`id: ${productId} qty: ${quantity}`);
            if (!state.cart[productId]) state.cart[productId] = 0;
            console.log(state.cart[productId])
            state.cart[productId] += quantity;
            console.log(state.cart[productId])
        },
        removeFromCart(state, cartItem) {
            const productId = cartItem.productId;
            const quantity = cartItem.quantity;
            if (!state.cart[productId]) state.cart[productId] = 0; // yes am adding just to remove
            state.cart[productId] -= quantity;
            if (state.cart[productId] <= 0) delete state.cart[productId];
        },
        deleteFromCart(state, productId) {
            delete state.cart[productId];
        },
        emptyCart(state) {
            state.cart = {}
        },
        updateQuantity(state, payload) {
            state.cart[payload.productId] = payload.quantity;
        },
        updateAccountAddress(state, address) {
            state.accountAddress = address;
        },
        updateAuthToken(state, token) {
            state.authToken = token;
        }
    },
    actions: {
        async fetchProducts({ commit }) {
            return fetch('/api/products.json')
                .then(response => response.json())
                .then(data => { console.log(`fetched ${data.length} products.`); commit('initializeProducts', data); });
        },
        addToCart({commit}, cartItem) {
            commit('addToCart', cartItem);
        },
        removeFromCart({ commit }, cartItem) {
            commit('removeFromCart', cartItem);
        },
        deleteFromCart({ commit }, productId) {
            commit('deleteFromCart', productId);
        },
        emptyCart({ commit }) {
            commit('emptyCart');
        },
        updateQuantity({commit}, cartItem) {
            commit('updateQuantity', cartItem);
        },
        updateAccountAddress({commit}, address) {
            commit('updateAccountAddress', address);
        },
        updateAuthToken({commit}, token) {
            commit('updateAuthToken', token);
        }
    },
    getters: {
        getAllProducts(state) {
            return state.products;
        },
        getProduct: state => productId => state.products.find(product => product.id==productId),
        getCart(state) {
            return state.cart;
        },
        getCartTotal(state, getters) {
            let total = new BigNumber(0);
            for (const productId in state.cart) {
                const product = (getters.getProduct)(productId);
                total = total.plus( new BigNumber(product.price).multipliedBy(state.cart[productId]) );
            }
            return total;
        },
        getMiniCart(state, getters) {
            const miniCart = { items: [], subtotal: new BigNumber(0) };
            for (let productId in state.cart) {
                const product = (getters.getProduct)(productId);
                miniCart.items.push({
                    id: productId,
                    name: product.name,
                    quantity: state.cart[productId],
                    image: product.images[0],
                    price: new BigNumber(product.price)
                });
                miniCart.subtotal = miniCart.subtotal.plus(product.price.multipliedBy(state.cart[productId]));
            }
            return miniCart;
        },
        getAccountAddress(state) {
            return state.accountAddress;
        },
        getAuthToken(state) {
            return state.authToken;
        }
    },
    plugins: [vuexLocal.plugin]
})

export default store
