<template>
<div>
    <div v-if="product">
        <div id="content" class="site-content">
            <div id="primary" class="content-area">
                <main id="main" class="site-main">
                    <div class="cont maincont">
                        <ul class="b-crumbs">
                            <li><router-link to="/">Home</router-link></li>
                            <li>Product</li>
                        </ul>
                        <article>
                        <div class="prod">
                            <div class="prod-slider-wrap prod-slider-shown">
                                <div class="flexslider prod-slider" id="prod-slider">
                                    <ul class="slides">
                                        <li>
                                            <a data-fancybox-group="prod" class="fancy-img" :href="product.images[1]">
                                                <img :src="product.images[1]" alt="">
                                            </a>
                                        </li>
                                        <li>
                                            <a data-fancybox-group="prod" class="fancy-img" :href="product.images[2]">
                                                <img :src="product.images[2]" alt="">
                                            </a>
                                        </li>
                                        <li>
                                            <a data-fancybox-group="prod" class="fancy-img" :href="product.images[0]">
                                                <img :src="product.images[0]" alt="">
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="prod-slider-count"><p><span class="count-cur">1</span> / <span class="count-all">2</span></p><p class="hover-label prod-slider-zoom"><i class="icon ion-search"></i><span>Zoom In</span></p></div>
                                </div>
                                <div class="flexslider prod-thumbs" id="prod-thumbs">
                                    <ul class="slides">
                                        <li>
                                            <img :src="product.images[1]" alt="">
                                        </li>
                                        <li>
                                            <img :src="product.images[2]" alt="">
                                        </li>
                                        <li>
                                            <img :src="product.images[0]" alt="">
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="prod-cont">
                                <!--<div class="prod-rating-wrap">
                                    <p data-rating="4" class="prod-rating">
                                        <i class="rating-ico" title="1"></i><i class="rating-ico" title="2"></i><i class="rating-ico" title="3"></i><i class="rating-ico" title="4"></i><i class="rating-ico" title="5"></i>
                                    </p>
                                    <p class="prod-rating-count">7</p>
                                </div>-->
                                <p class="prod-categs">
                                    <router-link :to="'/category/'+category" v-for="category of product.categories" :key="category">{{category}}&nbsp;&nbsp;&nbsp;</router-link>
                                </p>
                                <h1>{{product.name}}</h1>
                                <div class="variations_form cart">
                                    <p class="prod-price"><img class="prod-price-symbol" src="/img/10grans.png">{{price}}</p>
                                    <p class="prod-excerpt"><ReadMore :text="product.description"/></p>
                                    <div class="prod-add">
                                        <div class="variations">
                                            <div class="variations-row">
                                                <!--<div class="label"><label >Сolor</label></div>
                                                <div class="value">
                                                    <select>
                                                        <option value="">Choose an option</option>
                                                        <option value="blue">Blue</option>
                                                        <option value="green">Green</option>
                                                        <option value="yellow">Yellow</option>
                                                    </select>
                                                </div>
                                                -->
                                            </div>
                                        </div>
                                        <button type="submit" class="button" v-on:click.stop="addToCart"><i class="icon ion-android-cart"></i> Add to cart</button>
                                        <p class="qnt-wrap prod-li-qnt">
                                            <a href="#" class="qnt-plus prod-li-plus" v-on:click="changeQuantity(1, $event)"><i class="icon ion-arrow-up-b"></i></a>
                                            <input type="text" v-model="quantity">
                                            <a href="#" class="qnt-minus prod-li-minus" v-on:click="changeQuantity(-1, $event)"><i class="icon ion-arrow-down-b"></i></a>
                                        </p>
                                        <!--<div class="prod-li-favorites">
                                            <a href="wishlist.html" class="hover-label add_to_wishlist"><i class="icon ion-heart"></i><span>Add to Wishlist</span></a>
                                        </div>
                                        <p class="prod-li-compare">
                                            <a href="compare.html" class="hover-label prod-li-compare-btn"><span>Compare</span><i class="icon ion-arrow-swap"></i></a>
                                        </p>-->
                                    </div>
                                </div>
                                <div class="prod-props">
                                    <dl class="product_meta">
                                        <dt>SKU:</dt>
                                        <dd>{{product.id}}</dd>
                                    </dl>
                                </div>
                            </div>
                            <!--<p class="prod-badge">
                                <span class="badge-1">TOP SELLER</span>
                            </p>-->
                        </div>
                        </article>
                        <div class="prod-tabs-wrap">
                            <ul class="prod-tabs">
                                <li id="prod-desc" class="active" data-prodtab-num="1">
                                    <a data-prodtab="#prod-tab-1" href="#">Description</a>
                                </li>
                                <li data-prodtab-num="2" id="prod-props">
                                    <a data-prodtab="#prod-tab-2" href="#">Additional information</a>
                                </li>
                                <!--<li data-prodtab-num="3" id="prod-reviews">
                                    <a data-prodtab="#prod-tab-3" href="#">Reviews (7)</a>
                                </li>
                                <li class="prod-tabs-addreview">Add a review</li>-->	</ul>
                            <div class="prod-tab-cont">
                                <p data-prodtab-num="1" class="prod-tab-mob active" data-prodtab="#prod-tab-1">Description</p>
                                <div class="prod-tab page-styling prod-tab-desc" id="prod-tab-1">
                                    <p v-html="product.description"></p>
                                </div>
                                <p data-prodtab-num="2" class="prod-tab-mob" data-prodtab="#prod-tab-2">Additional information</p>
                                <div class="prod-tab" id="prod-tab-2">
                                    <dl class="prod-tab-props">
                                        <template v-for="i of product.information" :key="Object.keys(i)[0]">
                                            <dt>{{Object.keys(i)[0]}}</dt>
                                            <dd>{{Object.values(i)[0]}}</dd>
                                        </template>
                                    </dl>
                                </div>
                                <!--<p data-prodtab-num="3" class="prod-tab-mob" data-prodtab="#prod-tab-3">Reviews (7)</p>-->
                                <!--<div class="prod-tab prod-reviews" id="prod-tab-3">
                                    <div id="reviews">
                                        <div id="review_form_wrapper" class="prod-addreview-form">
                                            <div id="review_form">
                                                <div id="respond" class="comment-respond">
                                                    <p class="prod-tab-addreview" id="reply-title">Add a Review</p>
                                                    <form action="#" method="post" id="commentform" class="comment-form">
                                                        <div class="comment-form-rating">
                                                            <p class="stars"><span><a class="star-1" href="#">1</a><a class="star-2" href="#">2</a><a class="star-3" href="#">3</a><a class="star-4" href="#">4</a><a class="star-5" href="#">5</a></span></p>
                                                            <select>
                                                                <option value="">Rate…</option>
                                                                <option value="5">Perfect</option>
                                                                <option value="4">Good</option>
                                                                <option value="3">Average</option>
                                                                <option value="2">Not that bad</option>
                                                                <option value="1">Very Poor</option>
                                                            </select>
                                                        </div>
                                                        <textarea placeholder="Review"></textarea>
                                                        <input type="submit" class="submit" value="Add a Review">
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <ul id="comments">
                                            <li class="prod-review">
                                                <div>
                                                    <h3>admin</h3>
                                                    <p class="prod-review-rating" title="Rated 5 out of 5">
                                                        <i class="rating-ico"></i>
                                                        <i class="rating-ico"></i>
                                                        <i class="rating-ico"></i>
                                                        <i class="rating-ico"></i>
                                                        <i class="rating-ico"></i>
                                                        <time datetime="2017-05-09T14:16:55+00:00">May 9, 2017</time>
                                                    </p>
                                                    <p>Aliquam ligula quam, vehicula non porta at, venenatis ac purus. Pellentesque pharetra, turpis sit amet elementum faucibus, tellus nisi condimentum metus, nec scelerisque tortor velit ac purus. Aenean interdum velit quis nisi rhoncus ullamcorper. Cras lorem metus, imperdiet vel tortor vitae, malesuada pellentesque felis. Nulla varius mi a varius semper. Sed tristique ligula tincidunt pellentesque rhoncus. Praesent purus urna, imperdiet in pellentesque sed, hendrerit quis mauris. Maecenas consequat ultrices augue ac tempor. Etiam semper eu ligula id iaculis. Morbi scelerisque neque at velit porta, et fringilla nisl pulvinar. Duis at rhoncus est, nec cursus orci. Nunc malesuada tellus magna, in feugiat mauris placerat eu. Nullam elementum est id aliquam imperdiet.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>     
    </div>
    <div v-else>
        &nbsp;
    </div>
</div>
</template>
<style scoped>
.prod-price-symbol {
    width: 25px;
    height: 25px;
}
</style>
<script>
import { bus } from '../main'
import { BigNumber } from 'bignumber.js'
import ReadMore from '../components/ReadMore.vue'
export default {
    data() {
        return {
            product: null,
            quantity: 1
        }
    },
    components: {
        ReadMore
    },
    computed: {
        price() {
            return this.product.price.dividedBy(new BigNumber('1e+18')).toString();
        }
    },
    created() {
        this.$store.dispatch('fetchProducts').then(() => {
            this.product = this.$store.getters.getProduct(this.$route.params.id);
            setTimeout(() => { this.initSlider(); }, 1);
        });
    },
    mounted() {
    },
    methods: {
        addToCart() {
            this.$store.dispatch('addToCart', { productId: this.product.id, quantity: this.quantity });
            bus.emit('updated-cart');
            this.$router.push('/');
        },
        changeQuantity(amount, event) {
            event.stopPropagation();
            event.preventDefault();
            if ((amount > 0) || (amount < 0 && this.quantity > 1)) {
                this.quantity += amount;
            }
        },
        initSlider() {
            var slider_thumbs = '#prod-thumbs';
            var slider_main = '#prod-slider';

            window.jQuery(slider_thumbs).flexslider({
                animation: "slide",
                controlNav: false,
                animationLoop: false,
                slideshow: false,
                itemWidth: 97,
                itemMargin: 0,
                minItems: 5,
                maxItems: 5,
                asNavFor: slider_main,
                start: function(){
                    window.jQuery(slider_thumbs).resize();
                }
            });
            window.jQuery(slider_main).flexslider({
                animation: "fade",
                animationSpeed: 500,
                slideshow: false,
                animationLoop: false,
                smoothHeight: false,
                controlNav: false,
                sync: slider_thumbs,
                after: function(slider) {
                    window.jQuery('.prod-slider-count .count-cur').text(slider.currentSlide+1);
                }
            });
        }
    }
}
</script>
