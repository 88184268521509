<template>
<div class="form-group">
<h3>Shipping Address</h3>
<div class="blurb">
    Form uses Google for address completion, if you don't want this, block the
    script in your ad-blocker and the form will still work fine.
</div>

  <input type="street" 
         class="form-control" 
         id="autocomplete" 
         placeholder="Street">
  
  <input type="city" 
         class="form-control" 
         id="inputCity" 
         placeholder="City">
  
  <input type="state" 
         class="form-control" 
         id="inputState" 
         placeholder="State">
  
  <input type="zip" 
         class="form-control" 
         id="inputZip" 
         placeholder="Zip/Postal">
  
  <input type="county" 
         class="form-control" 
         id="inputCounty" 
         placeholder="County">
  
  <input type="country" 
         class="form-control" 
         id="inputCountry" 
         placeholder="Country">

    <div class="personal-info">
        All personal information is kept secure and completely confidential, and will
        <strong>NEVER</strong> be used for any purpose but onlygrans.xyz transactions and communication.
    </div>
</div>
</template>
<style scoped>
h3 {
    margin-bottom: .5em;
}

input, label {
  margin: 5px 5px;
}

.form-group {
  margin-left: 30px auto;
  margin-right: 30px auto;
  margin-bottom: 30px;
  /*min-width: 450px;*/
  width: 100%;
  min-height: 350px;
  display: inline-block;
  background-color: white;
  padding: 50px;
}

.form-control {
  float: left;
}

#inputStreet {
  width: 100%;
}

#inputCity {
  width: 50%;
}

#inputState {
  width: 15%;
}

#inputZip {
  width: 28%;
}

#inputCounty {
  width: 45%;
}

#inputCountry {
  width: 50%;
}
.personal-info {
    float: right;
}
.blurb {
    font-style: italic;
    font-size: x-small;
}
</style>
<script>
export default {
    created() {
    },
    mounted() {
        this.initAutocomplete();
    },
    methods: {
        initAutocomplete() {
            this.autocomplete = new google.maps.places.Autocomplete( //eslint-disable-line no-undef
                document.getElementById('autocomplete'),
                {type: ['geocode']}
            );

            this.autocomplete.addListener('place_changed', this.fillInAddress);
        },
        fillInAddress() {
            const componentForm = {
                autocomplete: ['street_number', 'route'],
                inputCity: 'locality',
                inputState: 'administrative_area_level_1',
                inputZip: 'postal_code',
                inputCounty: 'administrative_area_level_2',
                inputCountry: 'country'
            }

            const place = this.autocomplete.getPlace();
            console.log(place);

            for (const component in componentForm) {
                document.getElementById(component).disabled = false;
                document.getElementById(component).value = this.search(componentForm[component], place.address_components);
            }

            if (this.search('street_number', place.address_components) != '') {
                document.getElementById('autocomplete').value = this.search('street_number', place.address_components) + ' ';
            }
            document.getElementById('autocomplete').value += this.search('route', place.address_components);
        },
        search(type, placeObject) {
            for (var i = 0; i < placeObject.length; i++) {
                if (placeObject[i].types[0] === type) {
                    return placeObject[i].short_name;
                }
                else if (i === placeObject.length - 1) {
                    return '';
                }
            }
        }
    }
}
</script>
