<template>
<article class="cf-sm-6 cf-md-4 cf-lg-4 col-xs-6 col-sm-6 col-md-4 col-lg-4 sectgl-item">
    <div class="sectgl prod-i">
        <div class="prod-i-top">
            <router-link class="prod-i-img" :to="'/product/'+product.id">
                <img :src="product.images[0]" alt="">
            </router-link>
            <div class="prod-i-actions">
                <div class="prod-i-actions-in">
                    <!--<div class="prod-li-favorites">
                        <a href="wishlist.html" class="hover-label add_to_wishlist"><i class="icon ion-heart"></i><span>Add to Wishlist</span></a>
                    </div>-->
                    <!--<p class="prod-quickview">
                        <a href="#" class="hover-label quick-view"><i class="icon ion-plus"></i><span>Quick View</span></a>
                    </p>-->
                    <p class="prod-i-cart">
                        <a href="#" class="hover-label prod-addbtn" v-on:click="addToCart"><i class="icon ion-android-cart"></i><span>Add to Cart</span></a>
                    </p>
                    <!--<p class="prod-li-compare">
                        <a href="compare.html" class="hover-label prod-li-compare-btn"><span>Compare</span><i class="icon ion-arrow-swap"></i></a>
                    </p>-->
                </div>
            </div>
        </div>
        <div class="prod-i-bot">
            <div class="prod-i-info">
                <p class="prod-i-price"><img class="prod-price-symbol" src="/img/10grans.png">{{price}}</p>
                <p class="prod-i-categ"><router-link :to="'/category/'+product.categories[0]">{{product.categories[0]}}</router-link></p>
            </div>
            <h3 class="prod-i-ttl"><router-link :to="'/product/'+product.id">{{product.name}}</router-link></h3>
        </div>
    </div>
</article>
</template>
<style scoped>
.prod-i-price .prod-price-symbol {
    width: 15px;
    height: 15px;
}
</style>
<script>
import { bus } from '../main'
import { BigNumber } from 'bignumber.js'

export default {
    props: ['product'],
    computed: {
        price() { return new BigNumber(this.product.price).dividedBy(new BigNumber('1e+18')).toString(); }
    },
    methods: {
        addToCart(event) {
            event.stopPropagation();
            event.preventDefault();
            this.$store.dispatch('addToCart', { productId: this.product.id, quantity: 1 });
            bus.emit('updated-cart');
            bus.emit('toast', {text: 'Added to cart!'});
        }
    }
}
</script>