<template>
<div>
    <div id="content" class="site-content"><div id="primary" class="content-area width-normal">
        <main id="main" class="site-main">
            <div class="cont maincont">
                <h1 class="maincont-ttl">Shopping Cart</h1>
                <ul class="b-crumbs">
                    <li><router-link to="/">Home</router-link></li>
                    <li>Cart</li>
                </ul>
                <div class="page-styling">


                <div class="woocommerce prod-litems section-list">
                    <CartItem v-for="cartItem of cart" :key="cartItem.product.id" v-bind:cartItem="cartItem"/>
                </div>


                <div class="cart-actions" v-if="cart.length > 0">
                    <div class="coupon">
                        <input type="text" placeholder="Coupon code">
                        <input type="submit" class="button" value="Apply">
                    </div>
                    <div class="cart-collaterals">
                        <router-link to="/checkout" class="checkout-button button">Proceed to checkout</router-link>
                        <div class="order-total">
                            <p class="cart-totals-ttl">Total</p>
                            <p class="cart-totals-val"><img class="prod-price-symbol" src="/img/10grans.png">{{total}}</p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    Empty!
                </div>


            </div>
            </div>
        </main><!-- #main -->
    </div><!-- #primary -->    </div><!-- #content -->
</div>
</template>
<style scoped>
.prod-price-symbol {
    width: 14px;
    height: 14px;
}
</style>
<script>
import CartItem from '../components/CartItem.vue'
import {BigNumber} from 'bignumber.js'

export default {
    components: {
        CartItem
    },
    computed: {
        cart() {
            const richCart = [];
            for (const productId in this.$store.getters.getCart) {
                const product = (this.$store.getters.getProduct)(productId);
                const cartItem = { product, quantity: this.$store.getters.getCart[productId] }
                richCart.push(cartItem);
            }
            return richCart;
        },
        total() {
            let totalAmount = new BigNumber(0);
            for (const productId in this.$store.getters.getCart) {
                const product = (this.$store.getters.getProduct)(productId);
                const quantity = this.$store.getters.getCart[productId];
                const subtotal = new BigNumber(product.price).multipliedBy(quantity);
                totalAmount = totalAmount.plus(subtotal);
            }
            return totalAmount.dividedBy(new BigNumber('1e+18')).toString();
        },
        prettyPrice(bn) {
            return new BigNumber(bn).dividedBy(new BigNumber('1e+18')).toString();
        }
    }
}
</script>
