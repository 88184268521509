<template>
<div id="page" class="site">
    <div class="container-fluid page-styling site-header-before">
        <div class="row">
            <div class="col-lg-4">
                <ul class="links_list links_list-align-left align-center-desktop topbar-social">
                    <!--<li>
                        <p class="links_list-value">
                            <a href="http://facebook.com" target="_blank" rel="nofollow">
                                <i class="fa fa-facebook"></i>
                            </a>
                        </p>
                    </li>
                    <li>
                        <p class="links_list-value">
                            <a href="mailto:email@email.com" target="_blank" rel="nofollow">
                                <i class="fa fa-paper-plane"></i>
                            </a>
                        </p>
                    </li>
                    <li>
                        <p class="links_list-value">
                            <a href="http://pinterest.com" target="_blank" rel="nofollow">
                                <i class="fa fa-pinterest-p"></i>
                            </a>
                        </p>
                    </li>-->
                    <li>
                        <p class="links_list-value">
                            <a href="http://youtube.com" target="_blank" rel="nofollow">
                                <i class="fa fa-youtube-play"></i>
                            </a>
                        </p>
                    </li>
                    <li>
                        <p class="links_list-value">
                            <a href="https://fedi.cc/onlygrans" target="_blank" rel="nofollow">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </p>
                    </li>
                    <!--<li>
                        <p class="links_list-value">
                            <a href="http://google.com" target="_blank" rel="nofollow">
                                <i class="fa fa-google-plus"></i>
                            </a>
                        </p>
                    </li>
                    <li>
                        <p class="links_list-value">
                            <a href="http://instagram.com" target="_blank" rel="nofollow">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </p>
                    </li>-->
                </ul>
            </div>
            <div class="col-lg-8">
                <ul class="links_list links_list-align-right align-center-desktop topbar-contacts">
                    <!--<li>
                        <p class="links_list-label">
                            Our Address
                        </p>
                        <p class="links_list-value">
                            <a href="http://maps.google.com" target="_blank" rel="nofollow">15th Street, Miami, USA</a>
                        </p>
                    </li>-->
                    <!--<li>
                        <p class="links_list-label">Proudly Hosted in the USA</p>
                    </li>-->
                    <li>
                        <p class="links_list-label">
                            Contact Us
                        </p>
                        <p class="links_list-value">
                            <a href="mailto:shitposterclub@gmail.com">shitposterclub@gmail.com</a>
                        </p>
                    </li>
                    <!--
                    <li>
                        <p class="links_list-label">
                            Phone
                        </p>
                        <p class="links_list-value">
                            <a href="tel:4785929899">(478)-592-9899</a>
                        </p>
                    </li>
                    -->
                </ul>
            </div>
        </div>
    </div>


    <div class="site-header">

        <p class="h-logo">
            <router-link to="/"><img src="img/logo.png" alt="MultiShop"></router-link>
        </p><!--
    No Space
    --><div class="h-shop">

        <!--<form method="get" action="#" class="h-search" id="h-search">
            <input type="text" placeholder="Search...">
            <button type="submit"><i class="ion-search"></i></button>
        </form>-->

        <ul class="h-shop-links">
            <!--<li class="h-search-btn" id="h-search-btn"><i class="ion-search"></i></li>-->
            <!--<li class="h-shop-icon h-wishlist">
                <a title="Wishlist" href="wishlist.html">
                    <i class="ion-heart"></i>
                    <span>5</span>
                </a>
            </li>
            <li class="h-shop-icon h-compare">
                <a title="Compare List" href="compare.html">
                    <i class="ion-arrow-swap"></i>
                    <span>2</span>
                </a>
            </li>-->
            <!--<li class="h-shop-icon h-profile">
                <a href="auth.html" title="My Account">
                    <i class="ion-android-person"></i>
                </a>
                <ul class="h-profile-links">
                    <li><a href="auth.html">Login / Registration</a></li>
                    <li><a href="cart.html">Cart</a></li>
                    <li><a href="compare.html">Compare</a></li>
                    <li><a href="wishlist.html">Wishlist</a></li>
                </ul>
            </li>-->

            <MiniCart v-bind:miniCart="miniCart"/>

            <li class="h-menu-btn" id="h-menu-btn">
                <i class="ion-navicon"></i> Menu
            </li>
        </ul>
    </div><!--
    No Space
    --><div class="mainmenu">

        <nav id="h-menu" class="h-menu">
            <ul>
                <li class="menu-item">
                    <router-link to="/">Home</router-link>
                    <!--<ul class="sub-menu">
                        <li class="active">
                            <a href="index.html">Home 1</a>
                        </li>
                        <li>
                            <a href="index-2.html">Home 2</a>
                        </li>
                    </ul>-->
                </li>
                <li class="menu-item-has-children">
                    <router-link to="/gallery">Shop</router-link>
                    <ul class="sub-menu">
                        <li>
                            <router-link to="/cart">Shopping Cart</router-link>
                        </li>
                        <li>
                            <router-link to="/me">My Account</router-link>
                        </li>
                    </ul>
                </li>
                <!--<li>
                    <a href="elements.html">Elements</a>
                </li>
                <li class="menu-item-has-children">
                    <a href="blog.html">Blog</a>
                    <ul class="sub-menu">
                        <li>
                            <a href="blog.html">Blog Posts</a>
                        </li>
                        <li>
                            <a href="post.html">Standard Post</a>
                        </li>
                        <li>
                            <a href="post-slider.html">Slider Post</a>
                        </li>
                    </ul>
                </li>-->
                <li class="menu-item-has-children">
                    <router-link to="/about">Pages</router-link>
                    <ul class="sub-menu">
                        <li>
                            <router-link to="/about">About Us</router-link>
                        </li>
                        <li>
                            <router-link to="/gallery">Gallery</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>

    </div><!--
    No Space
--></div>

    <router-view></router-view>

    <div class="container-fluid blog-sb-widgets page-styling site-footer">
        <div class="row">
            <div class="col-sm-12 col-md-4 widget align-center-tablet f-logo-wrap">
                
                <router-link to="/" class="f-logo">
                    <img src="img/logo.png" alt="">
                </router-link>
                <button class="btn callback">Contact Us</button>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 align-center-mobile widget">
                <h3 class="widgettitle">Company</h3>
                <ul class="menu">
                    <li>
                        <router-link to="/">Front Page</router-link>
                    </li>
                    <li>
                        <router-link to="/about">About Us</router-link>
                    </li>
                    <!--<li>
                        <a href="contacts.html">Contacts</a>
                    </li>
                    <li>
                        <a href="index.html">Gallery</a>
                    </li>-->
                </ul>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 align-center-mobile widget">
                <h3 class="widgettitle">Shop</h3>
                <ul class="menu">
                    <li>
                        <router-link to="/category/Pillowcases">Pillow Cases</router-link>
                    </li>
                    <li>
                        <router-link to="/category/Stickers">Stickers</router-link>
                    </li>
                    <li>
                        <router-link to="/category/Poker%20Chips">Poker Chips</router-link>
                    </li>
                    <!--<li>
                        <a href="catalog-gallery.html">Pipes</a>
                    </li>-->
                </ul>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 align-center-mobile widget">
                <h3 class="widgettitle">Account</h3>
                <ul class="menu">
                    <!--<li>
                        <a href="wishlist.html">Wishlist</a>
                    </li>
                    <li>
                        <a href="compare.html">Compare</a>
                    </li>-->
                    <li>
                        <router-link to="/cart">Cart</router-link>
                    </li>
                    <li>
                        <router-link to="/me">My Account</router-link>
                    </li>
                </ul>
            </div>
            <!--<div class="col-xs-6 col-sm-3 col-md-2 align-center-mobile widget">
                <h3 class="widgettitle">Blog</h3>
                <ul class="menu">
                    <li>
                        <a href="blog.html">All Posts</a>
                    </li>
                    <li>
                        <a href="post.html">News</a>
                    </li>
                    <li>
                        <a href="post-slider.html">Articles</a>
                    </li>
                    <li>
                        <a href="blog.html">Reviews</a>
                    </li>
                </ul>
            </div>-->
        </div>
    </div>

    <div class="form-validate modal-form" id="modal-form">
        <form action="#" method="POST" class="form-validate">
            <h4>Contact Us</h4>
            <input type="text" placeholder="Your name" data-required="text" name="name">
            <input type="text" placeholder="Your phone" data-required="text" name="phone">
            <input type="text" placeholder="Your email" data-required="text" data-required-email="email" name="email">
            <input class="btn1" type="submit" value="Send">
        </form>
    </div>

    <div class="cont maincont quick-view-modal">
        <article>
            <div class="prod">
                <div class="prod-slider-wrap prod-slider-shown">
                    <div class="flexslider prod-slider" id="prod-slider">
                        <ul class="slides">
                            <li>
                                <a data-fancybox-group="prod" class="fancy-img" href="http://placehold.it/1000x1000">
                                    <img src="http://placehold.it/550x550" alt="">
                                </a>
                            </li>
                            <li>
                                <a data-fancybox-group="prod" class="fancy-img" href="http://placehold.it/1000x1000">
                                    <img src="http://placehold.it/550x550" alt="">
                                </a>
                            </li>
                            <li>
                                <a data-fancybox-group="prod" class="fancy-img" href="http://placehold.it/1000x1000">
                                    <img  src="http://placehold.it/550x550" alt="">
                                </a>
                            </li>
                        </ul>
                        <div class="prod-slider-count"><p><span class="count-cur">1</span> / <span class="count-all">3</span></p><p class="hover-label prod-slider-zoom"><i class="icon ion-search"></i><span>Zoom In</span></p></div>
                    </div>
                    <div class="flexslider prod-thumbs" id="prod-thumbs">
                        <ul class="slides">
                            <li>
                                <img src="http://placehold.it/550x550" alt="">
                            </li>
                            <li>
                                <img src="http://placehold.it/550x550" alt="">
                            </li>
                            <li>
                                <img src="http://placehold.it/550x550" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="prod-cont">
                    <div class="prod-rating-wrap">
                        <p data-rating="4" class="prod-rating">
                            <i class="rating-ico" title="1"></i><i class="rating-ico" title="2"></i><i class="rating-ico" title="3"></i><i class="rating-ico" title="4"></i><i class="rating-ico" title="5"></i>
                        </p>
                        <p class="prod-rating-count">7</p>
                    </div>
                    <p class="prod-categs"><a href="#">Lighting</a>, <a href="#">Tools</a></p>
                    <h2>Belt Sanders</h2>
                    <p class="prod-price">$25.00</p>
                    <p class="stock in-stock">7 in stock</p>
                    <p class="prod-excerpt">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget...</p>
                    <div class="prod-add">
                        <button type="submit"
                                class="button"><i class="icon ion-android-cart"></i> Add to cart
                        </button>
                        <p class="qnt-wrap prod-li-qnt">
                            <a href="#" class="qnt-plus prod-li-plus"><i class="icon ion-arrow-up-b"></i></a>
                            <input type="text" value="1">
                            <a href="#" class="qnt-minus prod-li-minus"><i class="icon ion-arrow-down-b"></i></a>
                        </p>
                        <div class="prod-li-favorites">
                            <a href="wishlist.html" class="hover-label add_to_wishlist"><i class="icon ion-heart"></i><span>Add to Wishlist</span></a>
                        </div>
                        <p class="prod-li-compare">
                            <a href="compare.html" class="hover-label prod-li-compare-btn"><span>Compare</span><i class="icon ion-arrow-swap"></i></a>
                        </p>
                    </div>
                </div>
            </div>
        </article>
    </div>
</div>
</template>
<style scoped>
</style>
<script>
import { bus } from './main'
import MiniCart from './components/MiniCart.vue'
import Toastify from 'toastify-js'

export default {
  components: {
    MiniCart
  },
  data() {
    return {
      miniCart: null
    }
  },
  created() {
    bus.on('updated-cart', this.refreshMiniCart);
    this.$store.dispatch('fetchProducts').then(() => {
      this.refreshMiniCart();
    });

    bus.on('toast', options => {
      this.postToast(options);
    });

    bus.on('update-cart', (cartItem) => {
      this.$store.dispatch('updateQuantity', cartItem);
      this.refreshMiniCart();
    });
  },
  methods: {
    refreshMiniCart() {
      this.miniCart = this.$store.getters.getMiniCart;
    },
    postToast(options) {
      Toastify({
        gravity: 'bottom',
        position: 'right',
        backgroundColor: '#f7af1d',
        ...options,
        }).showToast();
    }
  }
}
</script>