<template>
<article class="prod-li sectls">
    <div class="prod-li-inner">
        <a href="product.html" class="prod-li-img">
            <img :src="product.images[0]" alt="">
        </a>
        <div class="prod-li-cont">
            <div class="prod-li-ttl-wrap">
                <p>
                    <a href="#">{{product.categories[1]}}</a>
                </p>
                <h3><a href="product.html">{{product.name}}</a></h3>
            </div><!--
No Space
--><div class="prod-li-prices">
            <div class="prod-li-price-wrap">
                <p>Price</p>
                <p class="prod-li-price"><img class="prod-price-symbol" src="/img/10grans.png">{{prettyPrice(product.price)}}</p>
            </div>
            <div class="prod-li-qnt-wrap">
                <p class="qnt-wrap prod-li-qnt">
                    <a href="#" class="qnt-plus prod-li-plus" v-on:click="changeQuantity(1, $event)"><i class="icon ion-arrow-up-b"></i></a>
                    <input type="text" :value="quantity">
                    <a href="#" class="qnt-minus prod-li-minus" v-on:click="changeQuantity(-1, $event)"><i class="icon ion-arrow-down-b"></i></a>
                </p>
            </div>
            <div class="prod-li-total-wrap">
                <p>Total</p>
                <p class="prod-li-total"><img class="prod-price-symbol" src="/img/10grans.png">{{prettyPrice(total)}}</p>
            </div>
        </div><!--
No Space
--></div>
        <div class="prod-li-info">
            <div class="prod-li-rating-wrap">
                <!--<p data-rating="5" class="prod-li-rating">
                    <i class="rating-ico" title="1"></i><i class="rating-ico" title="2"></i><i class="rating-ico" title="3"></i><i class="rating-ico" title="4"></i><i class="rating-ico" title="5"></i>
                </p>
                <p class="prod-li-rating-count">12</p>-->
            </div>
            <p class="prod-li-add">
                <a href="#" class="button hover-label prod-addbtn" v-on:click.stop.prevent="addToCart"><i class="icon ion-android-cart"></i><span>Add to cart</span></a>
            </p>
            <!--<p class="prod-li-compare">
                <a href="compare.html" class="hover-label prod-li-compare-btn"><span>Compare</span><i class="icon ion-arrow-swap"></i></a>
            </p>
            <p class="prod-quickview">
                <a href="#" class="hover-label quick-view"><i class="icon ion-plus"></i><span>Quick View</span></a>
            </p>
            <div class="prod-li-favorites">
                <a href="wishlist.html" class="hover-label add_to_wishlist"><i class="icon ion-heart"></i><span>Add to Wishlist</span></a>
            </div>-->
            <p class="prod-li-information">
                <a href="#" class="hover-label"><i class="icon ion-more"></i><span>Show Information</span></a>
            </p>
        </div>
    </div>
    <div class="page-styling prod-li-informations">

        <dl class="prod-li-props">
            <template v-for="i of product.information" :key="Object.keys(i)[0]">
            <dt>{{Object.keys(i)[0]}}</dt>
            <dd>{{Object.values(i)[0]}}</dd>
        </template>
        </dl>
    </div>
</article>
</template>
<style scoped>
.prod-li-price, .prod-li-total {
    white-space: nowrap;
    width: 14px;
    height: 14px;
}
</style>
<script>
import { BigNumber } from 'bignumber.js'
import { bus } from '../main'
export default {
    props: ['product'],
    computed: {
        total() {
            return this.product.price.multipliedBy(this.quantity).toString();
        }
    },
    data() {
        return {
            quantity: 1
        }
    },
    methods: {
        addToCart() {
            bus.emit('update-cart', { productId: this.product.id, quantity: this.quantity });
            bus.emit('toast', {text: "Added to cart!"});
            this.quantity = 1;
        },
        changeQuantity(amount, event) {
            event.stopPropagation();
            event.preventDefault();
            if ((amount > 0) || (amount < 0 && this.quantity > 1)) {
                this.quantity += amount;
            }
        },
        prettyPrice(bn) {
            return (new BigNumber(bn).dividedBy(new BigNumber('1e+18'))).toString();
        }
    }
}
</script>