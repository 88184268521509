<template>
<div id="content" class="site-content">
    <div id="primary" class="content-area width-full">
        <main id="main" class="site-main">
            <div class="maincont page-styling page-full">


                <div class="heroblock" style="background-image: url(/img/room.jpg);">
                    <p class="heroblock-subttl"><a href="catalog-gallery.html">10grans Store</a></p>
                    <h3 class="heroblock-ttl">10Grans Lifestyle.</h3>
                    <!--<a href="catalog-gallery.html" class="btn">Read More</a>-->
                </div>


                <div class="container mb60 page-styling row-wrap-container row-wrap-nottl">
                    <p class="maincont-subttl">SPECIAL DEALS</p>
                    <h2 class="mb35 heading-multishop">Our Products</h2>
                    <div class="row prod-items prod-items-3" v-for="row of products" :key="row[0].id">
                        <ProductItem v-bind:product="product" v-for="product of row" :key="product.id" />
                    </div>
                    <!--<p class="special-more">
                        <a class="special-more-btn" href="#">Show More Products</a>
                    </p>-->
                </div>


                <div class="container mb40 page-styling row-wrap-container row-wrap-nottl front-icons">
                    <div class="row">
                        <div class="cf-lg-4 col-sm-4">
                            <div class="iconbox-item iconbox-i">
                                <div class="iconbox-i-top">
                                    <p class="iconbox-i-img withimg">
                                        <img src="img/1/front/1.png" alt="">
                                    </p>
                                    <h3>Peruse!</h3>
                                </div>
                                <p>Explore the site to find items you like.</p>
                            </div>
                        </div>
                        <div class="cf-lg-4 col-sm-4">
                            <div class="iconbox-item iconbox-i">
                                <div class="iconbox-i-top">
                                    <p class="iconbox-i-img withimg">
                                        <img src="img/1/front/2.png" alt="">
                                    </p>
                                    <h3>Order!</h3>
                                </div>
                                <p>Every item is purchased using the 10grans crypto-token, powered by the Ubiq blockchain.</p>
                            </div>
                        </div>
                        <div class="cf-lg-4 col-sm-4">
                            <div class="iconbox-item iconbox-i">
                                <div class="iconbox-i-top">
                                    <p class="iconbox-i-img withimg">
                                        <img src="img/1/front/3.png" alt="">
                                    </p>
                                    <h3>Get it!</h3>
                                </div>
                                <p>After payment, you will receive your purchase in two weeks.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <!--<div class="page-styling row-wrap-full front-image-half">
                    <section class="image-half image-half-right">
                        <div class="image-half-img" style="background-image: url(http://placehold.it/1006x498);">
                            <img src="http://placehold.it/1006x498" alt="">
                        </div>
                        <div class="cont image-half-cont">
                            <div class="image-half-inner">
                                <p class="maincont-subttl">Best sellers</p>
                                <h2>Best MultiShop Offers</h2>
                                <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                        <p>Phasellus rhoncus, dolor ac venenatis cursus, lacus tellus gravida ipsum, at maximus est diam ut tellus. Nunc ut risus sem.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>


                <div class="page-styling row-wrap-full front-image-half">
                    <section class="image-half image-half-left">
                        <div class="image-half-img" style="background-image: url(http://placehold.it/1006x498);">
                            <img src="http://placehold.it/1006x498" alt="">
                        </div>
                        <div class="cont image-half-cont">
                            <div class="image-half-inner">
                                <h2>Construction Tools for Sale</h2>
                                <form action="#" method="post" class="mb55 wpcf7 wpcf7-form">
                                    <p class="form-submit">
                        <span class="wpcf7-form-control-wrap">
                            <input type="email" placeholder="E-mail address">
                        </span>
                                        <input type="submit" value="Get a Free Bonus">
                                    </p>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>-->


                <!--<div class="page-styling row-wrap-full align-center front-title-block">
                    <h2>Are You Ready to Get Started</h2>
                    <a class="mb40 btn-multishop" href="catalog-gallery.html">Buy Now</a>
                </div>-->


                <!--<div class="container page-styling row-wrap-container row-wrap-nottl">
                    <div class="multishop_product_categories">
                        <div class="multishop_product_categories_item">
                            <a href="catalog-gallery.html">
                <span class="frontcategs-img">
                    <img src="http://placehold.it/48x48" alt="">
                </span>
                                <p>Tools</p>
                            </a>
                        </div>
                        <div class="multishop_product_categories_item">
                            <a href="catalog-gallery.html">
                <span class="frontcategs-img">
                    <img src="http://placehold.it/48x48" alt="">
                </span>
                                <p>Sanitary Engineering</p>
                            </a>
                        </div>
                        <div class="multishop_product_categories_item">
                            <a href="catalog-gallery.html">
                <span class="frontcategs-img">
                    <img src="http://placehold.it/48x48" alt="">
                </span>
                                <p>Pipes</p>
                            </a>
                        </div>
                        <div class="multishop_product_categories_item">
                            <a href="catalog-gallery.html">
                <span class="frontcategs-img">
                    <img src="http://placehold.it/48x48" alt="">
                </span>
                                <p>Other</p>
                            </a>
                        </div>
                        <div class="multishop_product_categories_item">
                            <a href="catalog-gallery.html">
                <span class="frontcategs-img">
                    <img src="http://placehold.it/48x48" alt="">
                </span>
                                <p>Lighting</p>
                            </a>
                        </div>
                        <div class="multishop_product_categories_item">
                            <a href="catalog-gallery.html">
                <span class="frontcategs-img">
                    <img src="http://placehold.it/48x48" alt="">
                </span>
                                <p>Fasteners</p>
                            </a>
                        </div>
                        <div class="multishop_product_categories_item">
                            <a href="catalog-gallery.html">
                <span class="frontcategs-img">
                    <img src="http://placehold.it/48x48" alt="">
                </span>
                                <p>Electricity</p>
                            </a>
                        </div>
                    </div>
                </div>-->


                <!--<div class="container page-styling row-wrap-container row-wrap-nottl">
                    <div class="row">
                        <div class="cf-lg-6 col-sm-6">
                            <div class="mb30 iconbox-item iconbox-i-4">
                                <div class="iconbox-i-top">
                                    <p class="iconbox-i-img">
                                        <i class="fa fa-heart"></i>
                                    </p>
                                    <h3><a href="#">Aliquam erat volutpat</a></h3>
                                </div>
                                <p>Vivamus non viverra est. Suspendisse vitae tellus et felis ullamcorper dapibus vitae sed dui. Donec fringilla sollicitudin justo, in aliquet urna gravida vitae.</p>
                            </div>
                            <div class="mb30 iconbox-item iconbox-i-4">
                                <div class="iconbox-i-top">
                                    <p class="iconbox-i-img">
                                        <i class="fa fa-plus-circle"></i>
                                    </p>
                                    <h3><a href="#"> Aenean eu mauris urna</a></h3>
                                </div>
                                <p>Vivamus non viverra est. Suspendisse vitae tellus et felis ullamcorper dapibus vitae sed dui. Donec fringilla sollicitudin justo, in aliquet urna gravida vitae.</p>
                            </div>
                        </div>
                        <div class="cf-lg-6 col-sm-6">
                            <div class="mb30 iconbox-item iconbox-i-4">
                                <div class="iconbox-i-top">
                                    <p class="iconbox-i-img">
                                        <i class="fa fa-star"></i>
                                    </p>
                                    <h3><a href="#">Quisque non commodo augue</a></h3>
                                </div>
                                <p>Vivamus non viverra est. Suspendisse vitae tellus et felis ullamcorper dapibus vitae sed dui. Donec fringilla sollicitudin justo, in aliquet urna gravida vitae.</p>
                            </div>
                            <div class="mb30 iconbox-item iconbox-i-4">
                                <div class="iconbox-i-top">
                                    <p class="iconbox-i-img">
                                        <i class="fa fa-check"></i>
                                    </p>
                                    <h3><a href="#">Maecenas interdum nisl non</a></h3>
                                </div>
                                <p>Vivamus non viverra est. Suspendisse vitae tellus et felis ullamcorper dapibus vitae sed dui. Donec fringilla sollicitudin justo, in aliquet urna gravida vitae.</p>
                            </div>
                        </div>
                    </div>
                </div>-->



            </div><!-- .maincont.page-styling.page-full -->
        </main><!-- #main -->
    </div><!-- #primary -->    
</div><!-- #content -->
</template>
<style scoped>

</style>
<script>
import ProductItem from '../components/ProductItem.vue'
export default {
    data() {
        return {
            products: [],
            cols: 3
        }
    },
    components: {
        ProductItem
    },
    created() {
        this.$store.dispatch('fetchProducts').then(() => {
            this.products = this.splitData(this.$store.getters.getAllProducts, this.cols);
        });
    },
    methods: {
        splitData(data, cols) {
            const out = [];

            let rowCount = Math.floor(data.length / cols);
            if (data.length % cols !== 0) rowCount += 1;
            for (let i=0; i<rowCount; ++i) { out.push([]) }

            let col = 0;
            let row = 0;
            for (let i=0; i<data.length; ++i) {
                if (col == cols) { col = 0; ++row; }
                out[row][col] = data[i];
                ++col;
            }

            return out;
        }
    }
}
</script>