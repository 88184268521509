<template>
    <div id="content" class="site-content"><div id="primary" class="content-area width-full">
        <main id="main" class="site-main">
            <div class="cont">
                <h1 class="maincont-ttl">About Us</h1>

                <ul class="b-crumbs">
                    <li><router-link to="/">Home</router-link></li>
                    <li>About Us</li>
                </ul>
            </div>
            <div class="maincont page-styling page-full">


                <div class="container mb70 page-styling row-wrap-container row-wrap-nottl">
                    <div
                            id="about-carousel"
                            class="flexslider content_carousel"
                            data-slideshow_speed="7000"
                            data-animation_speed="600"
                            data-pagination="true"
                            data-navigation="false"
                            data-stop_on_hover="false"
                    >
                        <ul class="slides">
                            <li class="page-styling content_carousel-slide">
                                <p class="content_carousel-img" style="background: url(/img/excited-people-jumping.jpg);">
                                    <img src="/img/excited-people-jumping.jpg" alt="">
                                </p>
                                <div class="content_carousel-cont">
                                    <h3>The 10grans Story</h3>
                                    <p>
                                        Started in 2017, 10grans was envisioned to be a community crypto-token based around "friends, fun, and finance." Since then we have lived up to that
                                        reputation. Literally hundreds of people have used our token to tip friends, buy services, or now, buy physical merchandise. Onlygrans
                                        is an extension and fulfilment of our promise to add more novelty to the universe and fight entropy.
                                    </p>
                                </div>
                            </li>
                            <li class="page-styling content_carousel-slide">
                                <p class="content_carousel-img" style="background: url(/img/gold-stuff.jpg);">
                                    <img src="/img/gold-stuff.jpg" alt="">
                                </p>
                                <div class="content_carousel-cont">
                                    <h3>Onlygrans.xyz: Physical Merchandise for Your 10grans</h3>
                                    <p>
                                        Long-promised, it has finally arrived: a way to buy physical merchandise with your 10grans. Built with love from the ground up using Vue.js, node.js and a five-dollar ThemeForest
                                        HTML5 template, we are lean, mean, and finally ready to take your money! We have stickers, poker chips, even degenerate body-pillow pillowcases, for a variety of 10grans,
                                        Fediverse, and adjacent concepts.
                                    </p>
                                    <p>
                                        Over time we hope to add more merchandise and offer more services using 10grans, onlygrans.xyz is only but the first step. We're excited for everything coming in the following year.
                                    </p>
                                </div>
                            </li>
                            <li class="page-styling content_carousel-slide">
                                <p class="content_carousel-img" style="background: url(/img/black-gold.jpg);">
                                    <img src="/img/black-gold.jpg" alt="">
                                </p>
                                <div class="content_carousel-cont">
                                    <h3>The Moon</h3>
                                    <p>
                                        Moon joined the Fediverse in early 2016, where he founded <a href="https://shitposter.club/" target="_blank">Shitposter Club</a>. This made a
                                        lot of people very angry and has been widely regarded as a bad move. There he met <a href="https://robek.world/">Robek</a> and a long-lasting
                                        creative collaboration and friendship was struck. Moon and Robek are the founders of <a href="https://10grans.cash/">10grans.cash</a>. Moon
                                        made onlygrans.xyz.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div><!-- .maincont.page-styling.page-full -->
        </main><!-- #main -->
    </div><!-- #primary -->    
</div><!-- #content -->
</template>
<style>
</style>
<script>
export default {
    data() {
        return {

        }
    },
    components: {},
    computed: {},
    mounted() {},
    created() {
        setTimeout(this.initCarousel, 1)
    },
    methods: {
        initCarousel(){
            const $ = window.jQuery;
            if ($('.content_carousel').length > 0) {
                $('.content_carousel').each(function () {
                    let $this = $(this);
                    var slideshow_speed;
                    let animation_speed;
                    let navigation;
                    let pagination;
                    let stop_on_hover;
                    if ($this.data('slideshow_speed') != '') {
                        slideshow_speed =  $this.data('slideshow_speed');
                    } else {
                        slideshow_speed =  '7000';
                    }
                    if ($this.data('animation_speed') != '') {
                        animation_speed =  $this.data('animation_speed');
                    } else {
                        animation_speed =  '600';
                    }
                    if ($this.data('navigation') == true) {
                        navigation =  true;
                    } else {
                        navigation =  false;
                    }
                    if ($this.data('pagination') == true) {
                        pagination =  true;
                    } else {
                        pagination =  false;
                    }
                    if ($this.data('stop_on_hover') == true) {
                        stop_on_hover =  true;
                    } else {
                        stop_on_hover =  false;
                    }
                    $('.content_carousel').flexslider({
                        pauseOnHover: stop_on_hover,
                        animationSpeed: animation_speed,
                        slideshowSpeed: slideshow_speed,
                        useCSS: false,
                        directionNav: navigation,
                        controlNav: pagination,
                        animation: 'fade',
                        slideshow: false,
                        animationLoop: true,
                        smoothHeight: true
                    });
                });
            }
        }
    }
}
</script>
