<template>
<p>
    <span v-html="formattedString"></span>
    <a href="#" v-if="!expanded" id="prod-showdesc" class="prod-excerpt-more" v-on:click.prevent="readMore(true)">{{moreStr}}</a>
</p>
</template>

<script>
export default {
  props: {
    moreStr: {
      type: String,
      default: "read more"
    },
    lessStr: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      required: true
    },
    maxChars: {
      type: Number,
      default: 300
    }
  },

  data() {
    return {
      expanded: false
    };
  },

  computed: {
    tooLong() {
        return this.text.length > this.maxChars;
    },
    formattedString() {
      if (!this.expanded && this.tooLong) {
        return this.text.substring(0, this.maxChars) + '...';
      }
      else {
          return this.text;
      }
    }
  },

  methods: {
    readMore(doReadMore) {
      this.expanded = doReadMore;
    },
  }
};
</script>