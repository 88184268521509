<template>
    <div id="content" class="site-content" v-if="allProducts.length > 0">

        <div id="primary" class="content-area">
            <main id="main" class="site-main">
                <div class="cont maincont">

                    <div class="section-top">

                        <h1 class="maincont-ttl">Shop</h1>
                        <ul class="b-crumbs">
                            <li><router-link to="/">Home</router-link></li>
                            <li>Category</li>
                        </ul>
                        <!--<div class="section-top-sort">
                            <div class="section-view">
                                <p>View</p>
                                <div class="dropdown-wrap">
                                    <p class="dropdown-title section-view-ttl">Gallery</p>
                                    <ul class="dropdown-list">
                                        <li>
                                            <a href="catalog-list.html">List</a>
                                        </li>
                                        <li class="active">
                                            <a href="catalog-gallery.html">Gallery</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="section-sort">
                                <p>Sort</p>
                                <div class="dropdown-wrap">
                                    <p class="dropdown-title section-sort-ttl">Newness</p>
                                    <ul class="dropdown-list">
                                        <li>
                                            <a href="#">Popularity</a>
                                        </li>
                                        <li>
                                            <a href="#">Average rating</a>
                                        </li>
                                        <li class="active">
                                            <a href="#">Newness</a>
                                        </li>
                                        <li>
                                            <a href="#">Price: low to high</a>
                                        </li>
                                        <li>
                                            <a href="#">Price: high to low</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <form method="post" class="products-per-page">
                                <p>Per Page</p>
                                <select>
                                    <option value="12" selected="selected">12</option>
                                    <option value="24">24</option>
                                    <option value="48">48</option>
                                    <option value="-1">All</option>
                                </select>
                            </form>
                        </div>-->
                    </div>

                    <!-- To make Sidebar "Not Sticky" just remove  id="section-list-withsb" -->
                    <div class="section-wrap-withsb">
                        <aside class="blog-sb-widgets section-sb" id="section-sb">
                            <div class="theiaStickySidebar">
                                <p class="section-filter-toggle filter_hidden">
                                    <a href="#" id="section-filter-toggle-btn">Show Filter</a>
                                </p>
                                <div class="section-filter">
                                    <div class="section-filter">
                                        <div class="blog-sb-widget multishopcategories_widget">
                                            <h3 class="widgettitle">Categories</h3>
                                            <div class="section-sb-current">
                                                <ul class="section-sb-list">
                                                    <li v-for="category of categories" :key="category.name">
                                                        <router-link :to="'/category/'+category.name"><span class="section-sb-label">{{category.name}} <span class="count">{{category.value}}</span></span></router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>


                                        <!--<div class="blog-sb-widget multishopfeaturedproducts_widget">
                                            <h3 class="widgettitle">Featured</h3>
                                            <div class="products-featured-wrap">
                                                <div class="products-featured">
                                                    <p class="products-featured-categ">
                                                        <a href="#">Tools</a>
                                                    </p>
                                                    <h5 class="products-featured-ttl"><a href="product.html">Rechargeable Battery</a></h5>
                                                    <p class="products-featured-price">$201.00</p>
                                                </div>
                                                <div class="products-featured">
                                                    <p class="products-featured-categ">
                                                        <a href="#">Pipes</a>
                                                    </p>
                                                    <h5 class="products-featured-ttl"><a href="product.html">Wholesale Brass</a></h5>
                                                    <p class="products-featured-price">$15.00</p>
                                                </div>
                                                <div class="products-featured">
                                                    <p class="products-featured-categ">
                                                        <a href="#">Pipes</a>
                                                    </p>
                                                    <h5 class="products-featured-ttl"><a href="product.html">Fittings Pipe</a></h5>
                                                    <p class="products-featured-price">$77.00</p>
                                                </div>
                                                <div class="products-featured">
                                                    <p class="products-featured-categ">
                                                        <a href="#">Pipes</a>
                                                    </p>
                                                    <h5 class="products-featured-ttl"><a href="product.html">Fittings Pipe</a></h5>
                                                    <p class="products-featured-price">$105.00</p>
                                                </div>
                                                <div class="products-featured">
                                                    <p class="products-featured-categ">
                                                        <a href="#">Fasteners</a>
                                                    </p>
                                                    <h5 class="products-featured-ttl"><a href="product.html">Flat Bolt</a></h5>
                                                    <p class="products-featured-price">$45.00</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="blog-sb-widget multishopbrands_widget">
                                            <h3 class="widgettitle">Brands</h3>
                                            <ul class="brands-list-sb">
                                                <li>
                                                    <a href="#">HOOK <span class="count">5</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">Fuel Tank <span class="count">7</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">BMP <span class="count">17</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">BISON <span class="count">9</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">AIR <span class="count">6</span></a>
                                                </li>
                                            </ul>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </aside>        <div class="section-list-withsb" id="section-list-withsb">
                        <div class="theiaStickySidebar">

                            <div class="prod-litems section-list">
                                <CategoryItem v-for="product of category" :key="product.id" v-bind:product="product" />
                            </div>

                            <!--<ul class="page-numbers">
                                <li><span class="page-numbers current">1</span></li>
                                <li><a class="page-numbers" href="#">2</a></li>
                                <li><a class="page-numbers" href="#">3</a></li>
                                <li><a class="page-numbers" href="#">4</a></li>
                                <li><a class="next page-numbers" href="#"><i class="fa fa-angle-right"></i></a></li>
                            </ul>-->
                        </div><!-- .theiaStickySidebar -->
                    </div><!-- .section-list-withsb -->
                    </div><!-- .section-wrap-withsb -->

                </div>
            </main><!-- #main -->
        </div><!-- #primary -->

    </div><!-- #content -->
</template>
<style scoped>
</style>
<script>
import CategoryItem from '../components/CategoryItem.vue'
export default {
    data() {
        return {
            allProducts: []
        }
    },
    components: {
        CategoryItem
    },
    computed: {
        categories() {
            if (this.allProducts) {
                const pairs = {};
                const ret = [];
                for (const product of this.allProducts) {
                    for (const category of product.categories) {
                        if (!pairs[category]) { pairs[category] = 1 }
                        else { pairs[category]++; }
                    }
                }
                for (const key of Object.keys(pairs).sort()) {
                    ret.push({ name: key, value: pairs[key] });
                }
                return ret;
            }
            else {
                console.log("no products so no categories")
                return [];
            }
        },
        category() {
            const filtered = this.allProducts.filter(product => product.categories.includes(this.$route.params.category));
            return filtered;
        }
    },
    created() {
        this.$store.dispatch('fetchProducts').then(() => {
            this.allProducts = this.$store.getters.getAllProducts;
        }).then(() => { this.categories; });
    },
    methods: {

    }
}
</script>
