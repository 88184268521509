<template>
<li class="h-cart">
    <router-link to="/cart">
        <p class="h-cart-icon">
            <i class="ion-android-cart"></i>
            <span>{{itemCount}}</span>
        </p>
        <p class="h-cart-total" v-if="itemCount > 0"><img class="prod-price-symbol" src="/img/10grans.png">{{prettyPrice(subtotal)}}</p>
    </router-link>
    <div class="widget_shopping_cart">
        <div class="widget_shopping_cart_content">
            <ul class="cart_list">
                <li v-if="itemCount == 0">Empty!</li>
                <li v-else v-for="item of miniCart.items" :key="item.id">
                    <a href="#" class="remove" v-on:click="deleteItem(item.id, $event)">&times;</a>
                    <router-link :to="'/product/'+item.id">
                        <img :src="item.image" alt="">
                        {{item.name}}
                    </router-link>
                    <span class="quantity">{{item.quantity}} &times; <img class="prod-price-symbol" src="/img/10grans.png"> {{prettyPrice(item.price)}}</span>
                </li>
            </ul>
            <p class="total"><b>Subtotal:</b> <img class="prod-price-symbol" src="/img/10grans.png"> {{prettyPrice(subtotal)}}</p>
            <p class="buttons">
                <router-link to="/cart" class="button">View Cart</router-link>
                <router-link to="/checkout" class="button">Checkout</router-link>
            </p>
        </div>
    </div>
</li>
</template>
<style scoped>
.h-cart-total .prod-price-symbol {
    width: 12px;
    height: 12px;
}
.total .prod-price-symbol {
    width: 10px;
    height: 10px;
}
.cart_list .quantity .prod-price-symbol {
    width: 10px;
    height: 10px;
    float: none;
}
</style>
<script>
import { bus } from '../main'
import { BigNumber } from 'bignumber.js'

export default {
    props: ['miniCart'],
    computed: {
        itemCount() {
            return this.miniCart ? this.miniCart.items.length : 0;
        },
        subtotal() {
            return this.miniCart ? this.miniCart.subtotal.toString() : 0;
        },

    },
    methods: {
        deleteItem(productId, event) {
            event.stopPropagation();
            event.preventDefault();
            this.$store.dispatch('deleteFromCart', productId);
            bus.emit('updated-cart');
            bus.emit('toast', {text: 'Item removed item from cart'});
        },
        prettyPrice(bn) {
            return (new BigNumber(bn).dividedBy(new BigNumber('1e+18'))).toString();
        }
    }
}
</script>
