<template>
<div class="payment-box">
    <h3>Payment</h3>
    <table class="payment container">
        <thead>
        <tr>
            <th class="name">Item</th><th class="quantity">Qty</th><th class="price">Price</th><th class="subtotal">Subtotal</th>
        </tr>
        </thead>
        <tbody>
            <tr v-for="item of cart" :key="item.product.id">
                <td class="name">{{item.product.name}}</td>
                <td class="quantity">{{item.quantity}}</td>
                <td class="price"><img class="prod-price-symbol" src="/img/10grans.png">{{prettyPrice(item.product.price)}}</td>
                <td class="subtotal"><img class="prod-price-symbol" src="/img/10grans.png">{{prettyPrice(item.subtotal)}}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr><td colspan="3" class="total-label">Total Payment:</td><td class="total"><img class="prod-price-symbol" src="/img/10grans.png">{{prettyPrice(total)}}</td></tr>
        </tfoot>
    </table>
</div>
</template>
<style scoped>
.payment-box {
  margin-left: 30px auto;
  margin-right: 30px auto;
  margin-bottom: 30px;
  /*min-width: 450px;*/
  min-height: 350px;
  width: 100%;
  display: inline-block;
  background-color: white;
  padding: 50px;
}

.total-label {
    text-align: right;
    font-weight: bold;
}
.total {
    font-weight: bold;
}
.payment tfoot tr td {
    border-top: 1px solid black;
}
.payment thead tr th {
    background-color: #f7af1d;
    overflow: hidden;
}
.total .prod-price-symbol, .payment .price .prod-price-symbol,  .payment .subtotal .prod-price-symbol{
    width: 14px;
    height: 14px;
}
@media screen and (min-width: 800px) {
    .quantity {
        width: 4em;
    }
    .price, .subtotal {
        width: 8em;
    }
}

@media only screen and (max-width: 600px) {
    .payment tr {
        font-size: smaller;
    }
    .payment tr th {
        padding-right: .5em;
        padding-left: .5em;
    }
    .name {
        width: 6em;
        overflow: hidden;
    }
    .quantity {
        width: 2em;
        overflow: hidden;
        white-space: nowrap;
    }
    .price, .subtotal {
        width: 4em;
        overflow: hidden;
        white-space: nowrap;
    }
    .prod-price-symbol {
        width: 6px;
        height: 6px;
        white-space: nowrap;
    }
}
</style>
<script>
import {BigNumber} from 'bignumber.js'

export default {
    data() {
        return {

        }
    },
    computed: {
        cart() {
            const richCart = [];
            const cartLength = Object.keys(this.$store.getters.getCart).length;
            console.log(`items in cart: ${cartLength}.`);
            for (const productId in this.$store.getters.getCart) {
                const product = (this.$store.getters.getProduct)(productId);
                const quantity = this.$store.getters.getCart[productId];
                const subtotal = new BigNumber(product.price).multipliedBy(quantity);
                const cartItem = { product, quantity, subtotal }
                richCart.push(cartItem);
            }
            return richCart;
        },
        total() {
            let totalAmount = new BigNumber(0);
            for (const productId in this.$store.getters.getCart) {
                const product = (this.$store.getters.getProduct)(productId);
                const quantity = this.$store.getters.getCart[productId];
                const subtotal = new BigNumber(product.price).multipliedBy(quantity);
                totalAmount = totalAmount.plus(subtotal);
            }
            return totalAmount;
        }
    },
    created() {

    },
    methods: {
        prettyPrice(bn) {
            return (new BigNumber(bn).dividedBy(new BigNumber('1e+18'))).toString();
        }
    }
}
</script>