import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Cart from '../views/Cart.vue'
import Me from '../views/Me.vue'
import Gallery from '../views/Gallery.vue'
import Category from '../views/Category.vue'
import Product from '../views/Product.vue'
import Checkout from '../views/Checkout.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
    { path: '/cart', component: Cart },
    { path: '/me', component: Me },
    { path: '/gallery', component: Gallery },
    { path: '/category/:category', component: Category },
    { path: '/product/:id', component: Product },
    { path: '/checkout', component: Checkout },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router